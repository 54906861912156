<template>
    <div class="container">

        <div class="row">

            <div class="col-lg-12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </div>

            <div class="col-lg-12">

                <div class="card elevation-5">
                    <div class="card-body">
                        <v-spacer></v-spacer>
                        <div class="row">
                            <div class="col-lg-2 col-6">
                                <v-autocomplete v-model="millModel" :items="millIDLists" default="" item-value="mill_id" item-text="mill_name" label="Mill" clearable dense prepend-icon="mdi-factory"></v-autocomplete>
                            </div>
                            <div class="col-lg-2 col-6">
                                <v-autocomplete v-model="typeModel" :items="typeLists" default="" item-value="value" item-text="text" label="Type" @change="(event) => typeOnChange(event)" dense prepend-icon="mdi-checkbox-multiple-blank-circle"></v-autocomplete>
                            </div>
                            <div class="col-lg-2 col-6">
                                <v-autocomplete v-model="regionModel" :items="regionLists" default="" item-value="region" item-text="region" :label=labelRegion clearable dense :disabled="disabled_region == true"  @change="(event) => regionOnChange(event)" prepend-icon="mdi-city"></v-autocomplete>
                            </div>
                            <div class="col-lg-3 col-6">
                                <v-autocomplete v-model="officeModel" :items="officeLists" default="" item-value="office_id" item-text="office" :label=labelBranch clearable dense :disabled="disabled_office == true"  @change="(event) => officeOnChange(event)" prepend-icon="mdi-home-variant"></v-autocomplete>
                            </div>
                            <div class="col-lg-3 col-6">
                                <v-autocomplete v-model="salesModel" :items="salesLists" default="" item-value="SalesId" item-text="NamaSales" :label=labelSales clearable dense :disabled="disabled_salesman == true" prepend-icon="mdi-account"></v-autocomplete>
                            </div>
                            <div class="col-lg-2 col-6">
                                <v-autocomplete v-model="categoryGroupModel" :items="categoryGroupLists" default="" item-value="cat_group" item-text="cat_group" label="Category Group" @change="(event) => categoryGroupOnChange(event)" clearable dense prepend-icon="mdi-checkbox-multiple-blank"></v-autocomplete>
                            </div>
                            <div class="col-lg-2 col-6">
                                <v-autocomplete v-model="categoryModel" :items="categoryLists" default="" item-value="category_id" item-text="cat_desc" label="Category" clearable dense prepend-icon="mdi-checkbox-multiple-blank"></v-autocomplete>
                            </div>
                            <div class="col-lg-2 col-6">
                                <v-dialog ref="dialog" v-model="modalDateFrom" :return-value.sync="dateFrom" persistent width="290px" >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dateFrom" label="Start Period" readonly v-bind="attrs" v-on="on" dense clearable prepend-icon="mdi-calendar-check"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateFrom" type="month" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="modalDateFrom = false">Cancel</v-btn>
                                        <v-btn text color="primary" @click="$refs.dialog.save(dateFrom)">OK</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </div>
                            <div class="col-lg-2 col-6">
                                <v-dialog ref="dialog2" v-model="modalDateTo" :return-value.sync="dateTo" persistent width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dateTo" label="End Period" readonly v-bind="attrs" v-on="on" dense clearable prepend-icon="mdi-calendar-check"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateTo" type="month" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="modalDateTo = false">Cancel</v-btn>
                                        <v-btn text color="primary" @click="$refs.dialog2.save(dateTo)">OK</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </div>
                            <div class="col-lg-2 col-6">
                                <v-autocomplete v-model="salesActiveModel" :items="salesActiveLists" default="" item-value="value" item-text="text" label="Active Sales" clearable dense prepend-icon="mdi-checkbox-multiple-blank"></v-autocomplete>
                            </div>
                            <div class="col-lg-2 text-right">
                                <v-btn class="mt-3" color="primary" elevation="4" rounded small @click="getChartData()">Search<v-icon right dark>mdi-magnify</v-icon></v-btn>
                            </div>
                            <div class="col-lg-12">
                                <div class="row text-left badgeInfo">
                                    <div class="col-lg-3">
                                        <span class="badge rounded-pill bg-primary"><span class="mdi mdi-wallet"></span> Total : {{ sumIdr ? formatNumber(sumIdr) : '-'}}</span>
                                    </div>
                                    <div class="col-lg-3">
                                        <span class="badge rounded-pill bg-primary"><span class="mdi mdi-truck"></span> Orders : {{ sumOrder ? formatNumber(sumOrder) : '-'}}</span>
                                    </div>
                                    <div class="col-lg-3">
                                        <span class="badge rounded-pill bg-primary"><span class="mdi mdi-weight-kilogram"></span> Weight(Ton) : {{ sumWgt ? formatNumber(sumWgt) : '-'}}</span>
                                    </div>
                                    <div class="col-lg-3">
                                        <span class="badge rounded-pill bg-primary"><span class="mdi mdi-account-multiple"></span> Customers : {{ sumCust ? formatNumber(sumCust) : '-'}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 cardGraph">
                <div class="card elevation-5">
                    <div class="card-body">
                        <div id="chartContainer1" style="height: 350px; width: 100%;"></div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 cardGraph2">
                <div class="card elevation-5">
                    <div class="card-body">
                        <div id="chartContainer2" style="height: 350px; width: 100%;"></div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 cardGraph3">
                <div class="card elevation-5">
                    <div class="card-body">
                        <div id="chartContainer3" style="height: 350px; width: 100%;"></div>
                    </div>
                </div>
            </div>

        </div>

        <div class="modal fade modal-xl" id="detailModal" tabindex="-1" aria-labelledby="detailModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content bgCustom3">
                <div class="modal-header">
                    <h5 class="modal-title" id="detailModalLabel">Detail of {{ detailModalTitle }}</h5>
                </div>
                <div class="modal-body">
                            
                        <v-data-table :headers="headers" :items="itemDetailLists" class="elevation-1" page-count="5" :search="search" :loading="loadingDetail" dense>
                            <template v-slot:top>
                                <v-toolbar flat color="white">
                                    <div class="d-flex w-25">
                                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                    </div>
                                </v-toolbar>
                            </template>
                        </v-data-table>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" data-bs-dismiss="modal" >Close</button>
                </div>
                </div>
            </div>
        </div>

    </div>
    
</template>

<script>

export default {
    data() {
        return {
            breadcumbs: [
                {
                text: 'E-Kencana',
                disabled: false,
                href: '/admin/kmb',
                },
                {
                text: 'Analysis',
                disabled: false,
                href: '#',
                },
                {
                text: 'Order Analysis',
                disabled: true,
                href: '#',
                },
            ],
            millModel: '',
            millIDLists: [],
            labelRegion: 'Region',
            regionModel: '',
            regionLists: [],
            labelBranch: 'Branch Office',
            officeModel: '',
            officeLists: [],
            labelSales: 'Salesman',
            salesModel: '',
            salesLists: [],
            categoryGroupModel: '',
            categoryGroupLists: [],
            categoryModel: '',
            categoryLists: [],
            modalDateFrom: false,
            dateFrom: '',
            modalDateTo: false,
            dateTo: '',
            typeModel: '',
            typeLists: [],
            // typeLists: [
            //     {
            //         type_id: '',
            //         type_text: 'All'
            //     },
            //     {
            //         type_id: 'RETAIL',
            //         type_text: 'RETAIL'
            //     },
            //     {
            //         type_id: 'PROJECT',
            //         type_text: 'PROJECT'
            //     },
            // ],
            sumIdr: '',
            sumOrder: '',
            sumWgt: '',
            sumCust: '',
            dateFromFormatted: '',
            dateToFormatted: '',
            disabled_mill: false,
            disabled_region: false,
            disabled_office: false,
            disabled_salesman: false,
            disabled_type: false,
            detailModalTitle: '',
            headers: [],
            itemDetailLists: [],
            loadingDetail: false,
            search: '',
            userGroup: '',
            appl_id: 'ANALYSIS',
            salesActiveModel: '',
            salesActiveLists: [
                {
                    value: '',
                    text: 'All'
                },
                {
                    value: 'Y',
                    text: 'YES'
                },
                {
                    value: 'N',
                    text: 'NO'
                },
            ]
        }
    },
    async mounted(){
        
        this.initialize()

    },
    methods:{
        
        async initialize(){

            this.$store.dispatch('setOverlay', true)
            document.querySelector(".cardGraph").style.display = "none";
            document.querySelector(".cardGraph2").style.display = "none";
            document.querySelector(".cardGraph3").style.display = "none";

            this.dateFrom = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 7)
            this.dateTo = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 7)

            await axios.get(`${process.env.VUE_APP_URL}/api/kmb/analysis`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                this.userGroup = res.data.userGroup
                this.millIDLists = res.data.mill
                if(res.data.type == 'R'){

                        this.typeModel = 'PROJECT'
                        this.typeLists = [
                            {
                                value: 'PROJECT',
                                text: 'PROJECT'
                            }
                        ]

                } else {

                        this.typeLists = [
                            {
                                value: '',
                                text: 'All'
                            },
                            {
                                value: 'RETAIL',
                                text: 'RETAIL'
                            },
                            {
                                value: 'PROJECT',
                                text: 'PROJECT'
                            },
                        ]

                }

                if(res.data.region.length == 1){
                    this.regionModel = res.data.region[0].region
                    this.regionLists = res.data.region
                    this.disabled_region = true
                } else {
                    this.regionLists = res.data.region
                    this.disabled_region = false
                }

                if(res.data.office.length == 1){
                    this.officeModel = res.data.office[0].office_id
                    this.officeLists = res.data.office
                    this.disabled_office = true
                } else {
                    this.officeLists = res.data.office
                    this.disabled_office = false
                }

                if(res.data.sales.length == 1){
                    this.salesModel = res.data.sales[0].SalesId
                    this.salesLists = res.data.sales
                    this.disabled_salesman = true
                    this.disabled_type = true
                } else {
                    this.salesLists = res.data.sales
                    this.disabled_salesman = false
                    this.disabled_type = false
                }
                
                this.categoryGroupLists = res.data.category
                this.categoryLists = res.data.category

                this.$store.dispatch('setOverlay', false)

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })
            
        },

        async getChartData(){

            this.sumIdr = ''
            this.sumOrder = ''
            this.sumWgt = ''
            this.sumCust = ''
            this.$store.dispatch('setOverlay', true)
            document.querySelector(".cardGraph").style.display = "none";
            document.querySelector(".cardGraph2").style.display = "none";
            document.querySelector(".cardGraph3").style.display = "none";

            if (this.dateFrom) {
                this.dateFromFormatted = new Date(this.dateFrom).toISOString().substr(0, 7).replace(/-/g,"")
            } else  {
                this.dateFromFormatted = ''
            }

            if (this.dateTo) {
                this.dateToFormatted = new Date(this.dateTo).toISOString().substr(0, 7).replace(/-/g,"")
            } else  {
                this.dateToFormatted = ''
            }

            await axios.post(`${process.env.VUE_APP_URL}/api/kmb/analysis/getChartData`, { 

                    mill: this.millModel ? this.millModel : '',
                    type: this.typeModel ? this.typeModel : '',
                    region: this.regionModel ? this.regionModel : '',
                    office: this.officeModel ? this.officeModel : '',
                    sales: this.salesModel ? this.salesModel : '',
                    categoryGroup: this.categoryGroupModel ? this.categoryGroupModel : '',
                    category: this.categoryModel ? this.categoryModel : '',
                    dateFrom: this.dateFromFormatted ? this.dateFromFormatted : '',
                    dateTo: this.dateToFormatted ? this.dateToFormatted : '',
                    activeSales: this.salesActiveModel ? this.salesActiveModel : '',

                },
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                }
            )
            .then(res => {

                if(res.data.data.length != 0){
                    document.querySelector(".cardGraph").style.display = "block";
                    document.querySelector(".cardGraph2").style.display = "block";
                    document.querySelector(".cardGraph3").style.display = "block";
                    this.sumIdr = res.data.totalSum
                    this.sumOrder = res.data.totalCountOrder
                    this.sumWgt = res.data.totalWgtOrder
                    this.sumCust = res.data.totalCountCustomer
                    this.renderChart(res.data.data, res.data.data2, res.data.data3, res.data.data4, res.data.data5, res.data.data6)
                    this.$store.dispatch('setOverlay', false)
                } else {
                    this.$store.dispatch('setOverlay', false)
                    document.querySelector(".cardGraph").style.display = "none";
                    document.querySelector(".cardGraph2").style.display = "none";
                    document.querySelector(".cardGraph3").style.display = "none";
                    Swal.fire({
                        text: 'Data not found !',
                        icon: 'warning',
                    })
                }
                
            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },

        renderChart(data, data2, data3, data4, data5, data6, period){

            var chart = new CanvasJS.Chart("chartContainer1", {
                animationEnabled: true,
                theme: "light2",
                title:{
                    text: "Order Analysis"
                },
                subtitles: [{
                    text: "Click for details",		
                    fontColor: "grey",
                }],
                axisX:{
                    crosshair: {
                        enabled: true,
                        snapToDataPoint: true,
                    },
                },
                axisY: {
                    title: "IDR x1.000.000",
                    titleFontSize: 24,
                    crosshair: {
                        enabled: true,
                        snapToDataPoint: true
                    },
                    gridColor: "#005f73"
                },
                axisY2: {
                    title: "Tons",
                    titleFontSize: 24,
                    },
                legend: {
                    fontColor: "red",
                    cursor: "pointer",
                    itemclick: this.toggleDataSeries
                },
                toolTip:{
                    shared:true
                },
                data: [{
                    type: "column",
                    indexLabel: "{y}",
                    click: this.getDetails,
                    name: 'IDR',
                    indexLabelFontSize: 12,
                    showInLegend: true,
                    indexLabelPlacement: "inside",  
                    indexLabelOrientation: "horizontal",
                    yValueFormatString: "###,###",
                    indexLabelFontColor: "#fee440",
                    // color: "#0f4c5c",
                    dataPoints: data,
                },
                {
                    type: "spline",
                    indexLabel: "{y}",
                    axisYType: "secondary",
                    name: 'Order',
                    indexLabelFontSize: 12,
                    showInLegend: true, 
                    indexLabelOrientation: "horizontal",
                    yValueFormatString: "###,###",
                    lineColor: "#a2d2ff",
                    indexLabelFontColor: "#a2d2ff",
                    dataPoints: data2,
                },
                {
                    type: "spline",
                    indexLabel: "{y}",
                    axisYType: "secondary",
                    name: 'Customer',
                    indexLabelFontSize: 12,
                    showInLegend: true, 
                    indexLabelOrientation: "horizontal",
                    yValueFormatString: "###,###",
                    lineColor: "#ffafcc",
                    indexLabelFontColor: "#ffafcc",
                    dataPoints: data3,
                },
                {
                    type: "spline",
                    indexLabel: "{y}",
                    axisYType: "secondary",
                    name: 'Wgt. Order',
                    indexLabelFontSize: 12,
                    showInLegend: true, 
                    indexLabelOrientation: "horizontal",
                    yValueFormatString: "###,###",
                    lineColor: "#fb5607",
                    indexLabelFontColor: "#fb5607",
                    dataPoints: data4,
                }]
            })

            var chart2 = new CanvasJS.Chart("chartContainer2", {
                animationEnabled: true,
                exportEnabled: false,
                theme: "light2",
                title:{
                    text: "Order by Category"
                },
                legend: {
                    fontColor: "red",
                    cursor: "pointer"
                },
                data: [{
                    indexLabel: "{label} {y}",
                    indexLabelFontColor: "#0f4c5c",
                    type: "pie",
                    showInLegend: true,
                    legendText: "{label}",
                    yValueFormatString: "##0.00\"%\"",
                    dataPoints: data5,
                }]
            })

            var chart3 = new CanvasJS.Chart("chartContainer3", {
                animationEnabled: true,
                exportEnabled: false,
                theme: "light2",
                title:{
                    text: "Order by Cust. Division"
                },
                legend: {
                    fontColor: "red",
                    cursor: "pointer"
                },
                data: [{
                    indexLabel: "{label} {y}",
                    indexLabelFontColor: "#0f4c5c",
                    type: "pie",
                    showInLegend: true,
                    legendText: "{label}",
                    yValueFormatString: "##0.00\"%\"",
                    dataPoints: data6,
                }]
            })

            chart.render()
            chart2.render()
            chart3.render()

        },

        toggleDataSeries(e) {
            if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
                e.dataSeries.visible = false;
            } else {
                e.dataSeries.visible = true;
            }
            e.chart.render();
        },

        async typeOnChange(id){

            // this.regionLists = []
            // this.officeLists = []

            // await axios.get(`${process.env.VUE_APP_URL}/api/kmb/analysis/typeOnChange?type=${id ? id : 'all'}`, { 
            //     headers: { 
            //         'Accept': 'application/json',
            //         'Content-Type': 'application/json',
            //         'Access-Control-Allow-Origin': '*',
            //         Authorization: `Bearer ${this.$store.getters.isTokenUser}`
            //     } 
            // })
            // .then(res => {

                // if(res.data.region.length == 1){
                //     this.regionModel = res.data.region[0].region
                //     this.labelRegion = 'Region(' + res.data.countRegion + ')'
                //     this.regionLists = res.data.region
                //     this.disabled_region = true
                // } else {
                //     this.regionLists = res.data.region
                //     this.labelRegion = 'Region(' + res.data.countRegion + ')'
                //     this.disabled_region = false
                // }

                // this.regionLists = res.data.region
                // this.labelRegion = 'Region(' + res.data.countRegion + ')'
            //     this.officeLists = res.data.office
            //     this.labelBranch = 'Branch Office(' + res.data.countOffice + ')'
            //     this.salesLists = res.data.sales
            //     this.labelSales = 'Salesman(' + res.data.countSales + ')'

            // })
            // .catch(err => {

            //     this.$store.dispatch('setOverlay', false)
            //     if(err.response.status == '401'){
            //         this.$router.push('/');
            //     }

            // })

        },

        async regionOnChange(id){

            await axios.get(`${process.env.VUE_APP_URL}/api/kmb/analysis/regionOnChange?type=${this.typeModel ? this.typeModel : ''}&region=${id ? id : ''}`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                this.officeLists = res.data.office
                this.labelBranch = 'Branch Office(' + res.data.countOffice + ')'
                this.salesLists = res.data.sales
                this.labelSales = 'Salesman(' + res.data.countSales + ')'

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },

        async officeOnChange(id){

            await axios.get(`${process.env.VUE_APP_URL}/api/kmb/analysis/officeOnChange?type=${this.typeModel ? this.typeModel : ''}&region=${this.regionModel ? this.regionModel : ''}&office=${id ? id : ''}`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                this.salesLists = res.data.sales
                this.labelSales = 'Salesman(' + res.data.countSales + ')'

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },

        formatNumber(value) {
            let val = (value/1).toFixed(0).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },

        async getDetails(e){

            $('#detailModal').modal('show')
            this.loadingDetail = true

            this.itemDetailLists = []
            this.detailModalTitle = 'Order Analysis Periode ' + e.dataPoint.label

            this.headers = [
                { text: 'PERIODE', value: 'periode', align: 'center'},
                { text: 'OFFICE ID', value: 'office', align: 'left'},
                { text: 'SALESMAN', value: 'salesman_name', align: 'left'},
                { text: 'TOTAL(IDR)', value: 'total_order', align: 'right'},
                { text: 'ORDERS', value: 'count_order', align: 'right'},
                { text: 'CUSTOMERS', value: 'count_customer', align: 'right'},
                { text: 'WEIGHT', value: 'wgt_order', align: 'right'},
                { text: 'CATEGORY', value: 'cat_desc', align: 'left'}
            ]

            await axios.post(`${process.env.VUE_APP_URL}/api/kmb/analysis/getDetails`, { 

                    mill: this.millModel ? this.millModel : '',
                    region: this.regionModel ? this.regionModel : '',
                    office: this.officeModel ? this.officeModel : '',
                    sales: this.salesModel ? this.salesModel : '',
                    categoryGroup: this.categoryGroupModel ? this.categoryGroupModel : '',
                    category: this.categoryModel ? this.categoryModel : '',
                    type: this.typeModel ? this.typeModel : '',
                    periode: e.dataPoint.periode,

                },
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                }
            )
            .then(res => {

                this.loadingDetail = false
                this.itemDetailLists = res.data.result
                
            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },

        async categoryGroupOnChange(id){

            await axios.get(`${process.env.VUE_APP_URL}/api/kmb/analysis/categoryGroupOnChange?id=${id ? id : ''}`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                this.categoryLists = res.data.result

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },

        currentPeriod() {

            const current = new Date();
            current.setDate(current.getDate() + 20);

            const date = current.getFullYear() + '-' + ('0' + (current.getMonth())).slice(-2);      
            return date;
        }

    }
    
}
</script>

<style scoped>

    .v-text-field {
        padding-top: 10px !important;
    }

    .v-breadcrumbs {
        padding: 5px 5px !important;
    }

    .badgeInfo {
        font-size: 1em;
        padding-bottom: 10px; 
        padding-left: 10px;
    }

    .cardCustom{
        padding-top: 10px;
    }

    .container{
        margin-bottom: 100px !important;
    }

    .v-text-field >>> input {
        font-size: 1em !important;
        font-weight: 100 !important;
        text-transform: capitalize !important;
    }
    .v-text-field >>> label {
        font-size: 0.8em !important;
    }
    .v-text-field >>> button {
        font-size: 0.8em !important;
    }

</style>